<template>
  <div class="container-fluid mt-2">
    <b-card>
      <validation-observer
        ref="accountRules"
        tag="form"
      >

        <b-row>
          <b-col
            cols="12"
            class="mb-2 mt-3"
          >
            <feather-icon
              icon="BookOpenIcon"
              class="mr-25"
            />
            <span class="mb-0 font-weight-bold h5">
              Réservez votre place dans :
            </span>
            <small class="text-muted"> parcour , spécialité , niveau </small>
          </b-col>

          <b-col
            md="4"
          >
            <b-form-group
              label="Parcour"
              label-for="parcour"
            >
              <validation-provider
                #default="{ errors }"
                name="parcour"
                rules="required"
              >
                <v-select
                  disabled
                  :value="speciality.parcour"
                  label="nom"
                  :clearable="false"
                  placeholder="Choisir Parcour"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
          >
            <b-form-group
              label="Spécialité"
              label-for="specialite"
            >
              <validation-provider
                #default="{ errors }"
                name="specialite"
                rules="required"
              >
                <v-select
                  disabled
                  :value="speciality"
                  :clearable="false"
                  label="nom"
                  :options="mySpecialites"
                  placeholder="Choisir Spécialité"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Niveau"
              label-for="niveau"
            >
              <validation-provider
                #default="{ errors }"
                name="Niveau"
                rules="required"
              >
                <v-select
                  v-model="lead.niveau"
                  disabled
                  :clearable="false"
                  label="Niveau"
                  :options="myNiveaux"
                  placeholder="Choisir Niveau"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-2 mt-3"
          >
            <feather-icon
              icon="UserIcon"
              class="mr-25"
            />
            <span class="mb-0 font-weight-bold h5">
              Données personnelles
            </span>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Civilité"
              label-for="civilite"
            >
              <validation-provider
                #default="{ errors }"
                name="civilite"
                rules="required"
              >
                <v-select
                  v-model="lead.civilite"
                  disabled
                  :clearable="false"
                  label="Civilité"
                  :options="civilites"
                  placeholder="Choisir Civilité"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Nom"
              label-for="nom"
            >
              <validation-provider
                #default="{ errors }"
                name="nom"
                rules="required"
              >
                <b-form-input
                  id="nom"
                  v-model="lead.nom"
                  disabled
                  placeholder="Nom"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Prénom"
              label-for="prenom"
            >
              <validation-provider
                #default="{ errors }"
                name="Prenom"
                rules="required"
              >
                <b-form-input
                  id="prenom"
                  v-model="lead.prenom"
                  disabled
                  placeholder="Prénom"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="CIN/Passport"
              label-for="cin"
            >
              <validation-provider
                #default="{ errors }"
                name="cin"
                rules="required"
              >
                <b-form-input
                  id="cin"
                  v-model="lead.cin"
                  disabled
                  placeholder="CIN/Passport"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              :label="$t('Country')"
              label-for="pays"
            >
              <validation-provider
                #default="{ errors }"
                name="pays"
                rules="required"
              >
                <b-form-input
                  id="cin"
                  v-model="lead.pays"
                  disabled
                  :placeholder="$t('Country')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <label for="example-datepicker">Date Naissance</label>
            <b-form-datepicker
              id="example-datepicker"
              v-model="lead.dateNaissance"
              disabled
              class="mb-1"
            />
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Lieu de naissance"
              label-for="lieuNaissance"
            >
              <validation-provider
                #default="{ errors }"
                name="lieuNaissance"
                rules="required"
              >
                <b-form-input
                  id="lieuNaissance"

                  v-model="lead.lieuNaissance"
                  disabled
                  placeholder="Lieu de naissance"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Téléphone"
              label-for="telephone"
            >
              <validation-provider
                #default="{ errors }"
                name="telephone"
                rules="required"
              >
                <b-form-input
                  id="telephone"
                  v-model="lead.telephone"
                  disabled
                  placeholder="Téléphone"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="lead.email"
                  disabled
                  placeholder="Email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Adresse"
              label-for="adress"
            >
              <validation-provider
                #default="{ errors }"
                name="adress"
                rules="required"
              >
                <b-form-input
                  id="adress"

                  v-model="lead.adress"
                  disabled
                  placeholder="Adresse"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-2 mt-3"
          >
            <feather-icon
              icon="BookmarkIcon"
              class="mr-25"
            />
            <span class="mb-0 font-weight-bold h5">
              Cursus scolaire
            </span>
          </b-col></b-row>
        <b-row>
          <b-col md="2">
            <b-form-group
              label="Baccalauréat"
              label-for="bac"
            >
              <validation-provider
                #default="{ errors }"
                name="bac"
                rules="required"
              >
                <v-select
                  v-model="lead.bac"

                  disabled
                  :clearable="false"
                  label="Baccalauréat"
                  :options="baccalaureats"
                  placeholder="Choisir Baccalauréat"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Titre"
              label-for="typeBac"
            >
              <validation-provider
                #default="{ errors }"
                name="titre"
                rules="required"
              >
                <b-form-input
                  id="typeBac"

                  v-model="lead.typeBac"
                  disabled
                  placeholder="Titre"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="Année d'obtention"
              label-for="anneeBac"
            >
              <validation-provider
                #default="{ errors }"
                name="Prenom"
                rules="required"
              >
                <b-form-input
                  id="anneeBac"

                  v-model="lead.anneeBac"
                  disabled
                  placeholder="Année d'obtention"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Upload du diplôme Bac ou équivalent"
              label-for="baccalaureat"
            >
              <validation-provider
                #default="{ errors }"
                name="baccalaureat"
                rules="required"
              >
                <b-row>
                  <b-col md="10">
                    <b-form-input
                      v-model="lead.uploadBac"
                      disabled
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                  </b-col>
                  <b-col md="2">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click.prevent="downloadBac(lead)"
                    >
                      Télécharger
                    </b-button>
                  </b-col></b-row>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>

          <b-col md="2">
            <b-form-group
              label="Dernier Diplôme autre que le Bac "
              label-for="baccalaureat"
            >
              <validation-provider
                #default="{ errors }"
                name="baccalaureat"
                rules="required"
              >
                <v-select
                  v-model="lead.diplome"

                  disabled
                  :clearable="false"
                  label="Dernier Diplôme autre que le Bac "
                  :options="diplomes"
                  placeholder="Dernier Diplôme autre que le Bac "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Titre"
              label-for="typeDiplome"
            >
              <validation-provider
                #default="{ errors }"
                name="typeDiplome"
                rules="required"
              >
                <b-form-input
                  id="typeDiplome"

                  v-model="lead.typeDiplome"
                  disabled
                  placeholder="Titre"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="Année d'obtention diplome"
              label-for="anneeDiplome"
            >
              <validation-provider
                #default="{ errors }"
                name="anneeDiplome"
                rules="required"
              >
                <b-form-input
                  id="anneeDiplome"
                  v-model="lead.anneeDiplome"
                  disabled
                  placeholder="Année d'obtention"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Upload du dernier diplôme"
              label-for="fileDiplome"
            >
              <validation-provider
                #default="{ errors }"
                name="fileDiplome"
                rules="required"
              ><b-row>
                 <b-col md="10">
                   <b-form-input
                     v-model="lead.uploadDiplome"
                     disabled
                     placeholder="Choose a file or drop it here..."
                     drop-placeholder="Drop file here..."
                   />
                 </b-col>

                 <b-col md="2">
                   <b-button
                     v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                     variant="outline-primary"
                     @click.prevent="downloadDiplome(lead)"
                   >
                     Télécharger
                   </b-button>
                 </b-col></b-row>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row v-if="lead.inscription === 'demande inscription envoyée' || lead.inscription === 'demande inscription acceptée' || lead.paiement === 'demande paiement envoyée'|| lead.paiement === 'demande paiement acceptée'">
          <b-col
            cols="12"
            class="mb-2 mt-3"
          >
            <feather-icon
              icon="BookmarkIcon"
              class="mr-25"
            />
            <span class="mb-0 font-weight-bold h5">
              Dossier d'inscrition
            </span>
          </b-col></b-row>
        <b-row
          v-if="lead.inscription === 'demande inscription envoyée' || lead.inscription === 'demande inscription acceptée' || lead.paiement === 'demande paiement envoyée'|| lead.paiement === 'demande paiement acceptée'"
        >
          <b-col
            md="6"
          >
            <b-form-group
              label="Upload copie du passeport ou CIN:"
              label-for="copieCin"
            >
              <validation-provider
                #default="{ errors }"
                name="copieCin"
                rules="required"
              >
                <b-row>
                  <b-col md="10">
                    <b-form-input
                      v-model="lead.uploadCin"
                      disabled
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    /></b-col>

                  <b-col md="2">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click.prevent="downloadCin(lead)"
                    >
                      Télécharger
                    </b-button>
                  </b-col></b-row>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Upload Acte de naissance"
              label-for="acteNaiss"
            >
              <validation-provider
                #default="{ errors }"
                name="acteNaiss"
                rules="required"
              >
                <b-row>
                  <b-col md="10">
                    <b-form-input
                      v-model="lead.uploadActNaiss"
                      disabled
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                  </b-col>
                  <b-col md="2">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click.prevent="downloadActNaiss(lead)"
                    >
                      Télécharger
                    </b-button>
                  </b-col>

                </b-row>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Upload Relevés de Notes :"
              label-for="releveNote"
            >
              <validation-provider
                #default="{ errors }"
                name="releveNote"
                rules="required"
              >
                <b-row>
                  <b-col md="10">
                    <b-form-input
                      v-model="lead.uploadNote"
                      disabled
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                  </b-col>

                  <b-col md="2">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click.prevent="downloadNote(lead)"
                    >
                      Télécharger
                    </b-button>
                  </b-col>
                </b-row>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="lead.paiement === 'demande paiement envoyée'|| lead.paiement === 'demande paiement acceptée'">
          <b-col
            cols="12"
            class="mb-2 mt-3"
          >
            <feather-icon
              icon="DollarSignIcon"
              class="mr-25"
            />
            <span class="mb-0 font-weight-bold h5">
              Paiement scolarité:
            </span>
          </b-col></b-row>
        <b-row v-if="lead.paiement === 'demande paiement envoyée'|| lead.paiement === 'demande paiement acceptée'">

          <b-col md="6">
            <b-form-group
              label="Méthode"
              label-for="methode"
            >
              <validation-provider
                #default="{ errors }"
                name="methode"
                rules="required"
              >
                <v-select
                  v-model="lead.methodePaiement"
                  disabled
                  :clearable="false"
                  label="Méthode"
                  :options="methodes"
                  placeholder="Choisir Méthode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Devise"
              label-for="devise"
            >
              <validation-provider
                #default="{ errors }"
                name="devise"
                rules="required"
              >
                <v-select
                  v-model="lead.devise"
                  disabled
                  :clearable="false"
                  label="Devise"
                  :options="devises"
                  placeholder="Choisir Devise"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Tranche"
              label-for="tranche"
            >
              <validation-provider
                #default="{ errors }"
                name="tranche"
                rules="required"
              >
                <v-select
                  v-model="lead.tranche"
                  disabled
                  :clearable="false"
                  label="Tranche"
                  :options="tranches"
                  placeholder="Choisir tranche"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Upload Relevés de Notes :"
              label-for="releveNote"
            >
              <validation-provider
                #default="{ errors }"
                name="releveNote"
                rules="required"
              >
                <b-row>
                  <b-col md="10">
                    <b-form-input
                      v-model="lead.uploadPaiement"
                      disabled
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                  </b-col>

                  <b-col md="2">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click.prevent="downloadJust(lead)"
                    >
                      Télécharger
                    </b-button>
                  </b-col>
                </b-row>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="paier"
            md="6"
          >
            <b-form-group
              label="Pièce justificative"
              label-for="uploadPaieme"
            >
              <validation-provider
                #default="{ errors }"
                name="uploadPaieme"
                rules="required"
              >
                <b-form-input
                  v-model="lead.uploadPaiement"
                  disabled
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div
          v-if="lead.admission === 'demande admission envoyée'"
          class="custom-search d-flex justify-content-end mt-3"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="acceptAdmission()"
          >
            Accepter admission
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="ml-2"
            @click="rejectAdmission()"
          >
            Réfuser admission
          </b-button>
        </div>

        <div
          v-if="lead.inscription === 'demande inscription envoyée'"
          class="custom-search d-flex justify-content-end mt-3"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="acceptInscription()"
          >
            Valider inscription
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="ml-2"
            @click="rejectInscription()"
          >
            Réfuser inscription
          </b-button>
        </div>

        <div
          v-if="lead.paiement === 'demande paiement envoyée'"
          class="custom-search d-flex justify-content-end mt-3"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="acceptPaiement()"
          >
            Valider Paiement
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="ml-2"
            @click="rejectPaiement()"
          >
            Réfuser Paiement
          </b-button>
        </div>
      </validation-observer>

    </b-card>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormDatepicker, BButton, BCard,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BCard,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      parcour: {},
      specialite: {},
      users: [],
      lead: {},
      parcours: [],
      specialites: [],
      niveaux: [],
      civilites: ['Mr', 'Mlle', 'Mme'],
      baccalaureats: ['oui', 'equivalence'],
      diplomes: ['oui', 'equivalence'],
      payes: [],
      selectedContry: '',
      selectedLanguage: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,

    }
  },
  computed: {
    speciality() {
      return this.specialites.find(item => item.id === this.lead.specialite_id)
    },

    items() {
      if (this.lead.admission === 'demande admission envoyée') {
        return [
          {
            Tâche: 'Données personnelles',
            Étiquette: {
              status: 'Vos données ont été envoyées avec succès',
              variant: 'success',
              icon: 'CheckIcon',
            },
          },
          {
            Tâche: 'Cursus scolaire',
            Étiquette: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
          },
          {
            Tâche: 'Choix du parcours',
            Étiquette: {
              status: 'Vos données ont été envoyées avec succès',
              variant: 'success',
              icon: 'CheckIcon',
            },
          },
          {
            Tâche: 'Etat de la demande',
            Étiquette: { status: 'Votre demande en cour de traitement', variant: 'warning', icon: 'CheckIcon' },
          },
          {
            Tâche: 'Attestation de préinscription',
            Étiquette: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
          },
        ]
      }
      if (this.lead.admission === 'demande admission acceptée') {
        return [
          {
            Tâche: 'Données personnelles',
            Étiquette: {
              status: 'Vos données ont été envoyées avec succès',
              variant: 'success',
              icon: 'CheckIcon',
            },
          },
          {
            Tâche: 'Cursus scolaire',
            Étiquette: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
          },
          {
            Tâche: 'Choix du parcours',
            Étiquette: {
              status: 'Vos données ont été envoyées avec succès',
              variant: 'success',
              icon: 'CheckIcon',
            },
          },
          {
            Tâche: 'Etat de la demande',
            Étiquette: { status: 'Votre demande a été acceptée', variant: 'succes', icon: 'CheckIcon' },
          },
          {
            Tâche: 'Attestation de préinscription',
            Étiquette: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
          },
        ]
      }
      if (this.lead.admission === 'demande admission refusée') {
        return [
          {
            Tâche: 'Données personnelles',
            Étiquette: {
              status: 'Vos données ont été envoyées avec succès',
              variant: 'success',
              icon: 'CheckIcon',
            },
          },
          {
            Tâche: 'Cursus scolaire',
            Étiquette: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
          },
          {
            Tâche: 'Choix du parcours',
            Étiquette: {
              status: 'Vos données ont été envoyées avec succès',
              variant: 'success',
              icon: 'CheckIcon',
            },
          },
          {
            Tâche: 'Etat de la demande',
            Étiquette: { status: 'Votre demande a été réfusée', variant: 'danger', icon: 'CheckIcon' },
          },
          {
            Tâche: 'Attestation de préinscription',
            Étiquette: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
          },
        ]
      }
      return []
    },

    mySpecialites() {
      return this.specialites.filter(item => item.parcour.id === this.parcour.id)
    },
    myNiveaux() {
      if (this.parcour.nom === 'Mastères') { return ['première année', 'deuxième année'] }
      if (this.parcour.nom === 'Licences') { return ['première année', 'deuxième année', 'troisième année'] }
      return []
    },
  },

  created() {
    this.getUsers()
    this.getParcours()
    this.getSpecialites()
    this.countries()
  },
  methods: {
    downloadDiplome({ uploadDiplome, nom }) {
      axios.get(`media/${uploadDiplome}`, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: response.data.type })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Diplome ${nom}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    downloadJust({ uploadPaiement, nom }) {
      axios.get(`media/${uploadPaiement}`, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: response.data.type })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Paiement ${nom}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    downloadNote({ uploadNote, nom }) {
      axios.get(`media/${uploadNote}`, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: response.data.type })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Note ${nom}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    downloadCin({ uploadCin, nom }) {
      axios.get(`media/${uploadCin}`, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: response.data.type })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Cin ${nom}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    downloadActNaiss({ uploadActNaiss, nom }) {
      axios.get(`media/${uploadActNaiss}`, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: response.data.type })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Acte Naissance ${nom}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    downloadBac({ uploadBac, nom }) {
      axios.get(`media/${uploadBac}`, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: response.data.type })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Bac ${nom}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    async acceptAdmission() {
      await axios.put(
        `auth/admission/${this.$route.params.id}/`, { email: this.lead.email, nom: this.lead.nom, admission: 'demande admission acceptée' },
      ).then(() => {
        this.getUsers()
        setTimeout(() => {
          this.showToast('success', 'top-center', 'demande admission acceptée')
        }, 1000)
        this.$router.push({ name: 'admin' })
      })
        .catch(error => {
          setTimeout(() => {
            this.showToast('danger', 'top-center', error.toString())
          }, 1000)
        })
    },
    async rejectAdmission() {
      await axios.put(
        `auth/admission/${this.$route.params.id}/`, { email: this.lead.email, nom: this.lead.nom, admission: 'demande admission refusée' },
      ).then(() => {
        this.getUsers()
        setTimeout(() => {
          this.showToast('success', 'top-center', 'demande admission refusée')
        }, 1000)
      })
        .catch(error => {
          setTimeout(() => {
            this.showToast('danger', 'top-center', error.toString())
          }, 1000)
        })
    },
    async acceptInscription() {
      await axios.put(
        `auth/validate-inscription/${this.$route.params.id}/`, { email: this.lead.email, nom: this.lead.nom, inscription: 'demande inscription acceptée' },
      ).then(() => {
        this.getUsers()
        setTimeout(() => {
          this.showToast('success', 'top-center', 'demande inscription acceptée')
        }, 1000)
        this.$router.push({ name: 'admin' })
      })
        .catch(error => {
          setTimeout(() => {
            this.showToast('danger', 'top-center', error.toString())
          }, 1000)
        })
    },
    async rejectInscription() {
      await axios.put(
        `auth/inscription/${this.$route.params.id}/`, { email: this.lead.email, nom: this.lead.nom, inscription: 'demande inscription refusée' },
      ).then(() => {
        this.getUsers()
        setTimeout(() => {
          this.showToast('success', 'top-center', 'demande inscription refusée')
        }, 1000)
      })
        .catch(error => {
          setTimeout(() => {
            this.showToast('danger', 'top-center', error.toString())
          }, 1000)
        })
    },
    async acceptPaiement() {
      await axios.put(
        `auth/validate-paiement/${this.$route.params.id}/`, { nom: this.lead.nom, email: this.lead.email, paiement: 'demande paiement acceptée' },
      ).then(() => {
        this.getUsers()
        setTimeout(() => {
          this.showToast('success', 'top-center', 'demande paiement acceptée')
        }, 1000)
        this.$router.push({ name: 'admin' })
      })
        .catch(error => {
          setTimeout(() => {
            this.showToast('danger', 'top-center', error.toString())
          }, 1000)
        })
    },
    async rejectPaiement() {
      await axios.put(
        `auth/validate-paiement/${this.$route.params.id}/`, { nom: this.lead.nom, email: this.lead.email, paiement: 'demande paiement refusée' },
      ).then(() => {
        this.getUsers()
        setTimeout(() => {
          this.showToast('success', 'top-center', 'demande paiement refusée')
        }, 1000)
      })
        .catch(error => {
          setTimeout(() => {
            this.showToast('danger', 'top-center', error.toString())
          }, 1000)
        })
    },
    async getParcours() {
      await axios.get('/parcours/').then(response => {
        this.parcours = response.data
      })
    },
    getUsers() {
      axios.get('/auth/users/').then(response => {
        const users = response.data
        this.lead = users.find(user => user.id === this.$route.params.id)
      })
    },

    async getSpecialites() {
      await axios.get('/specialites/').then(response => {
        this.specialites = response.data
      })
    },
    async countries() {
      await axios.get('https://restcountries.eu/rest/v2/all').then(response => {
        this.payes = response.data
      })
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    async validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(async success => {
          if (success && this.lead.admission === 'demande admission non envoyée') {
            const token = localStorage.getItem('token')
            axios.defaults.headers.common.Authorization = `Token ${token}`
            const formData = new FormData()
            formData.append('niveau', this.lead.niveau)
            formData.append('dateNaissance', this.lead.dateNaissance)
            formData.append('civilite', this.lead.civilite)
            formData.append('nom', this.lead.nom)
            formData.append('typeBac', this.lead.typeBac)
            formData.append('typeDiplome', this.lead.typeDiplome)
            formData.append('cin', this.lead.cin)
            formData.append('prenom', this.lead.prenom)
            formData.append('lieuNaissance', this.lead.lieuNaissance)
            formData.append('email', this.lead.email)
            formData.append('telephone', this.lead.telephone)
            formData.append('uploadBac', this.lead.uploadBac)
            formData.append('uploadDiplome', this.lead.uploadDiplome)
            formData.append('adress', this.lead.adress)
            formData.append('bac', this.lead.bac)
            formData.append('diplome', this.lead.diplome)
            formData.append('anneeBac', this.lead.anneeBac)
            formData.append('anneeDiplome', this.lead.anneeDiplome)
            formData.append('pays', this.lead.pays)
            formData.append('specialite', this.specialite.id)
            formData.append('username', `${this.lead.nom}  ${this.prenom}`)
            await axios.put(
              `/auth/update_profile/${localStorage.getItem('id')}/`, formData,
            )
              .then(() => {
                this.getUsers()
                setTimeout(() => {
                  this.showToast('success', 'top-center', 'Demande admission envoyéee avec succés')
                }, 1000)
                resolve(true)
              })
              .catch(error => {
                setTimeout(() => {
                  this.showToast('danger', 'top-center', error.toString())
                }, 1000)
              })
          } else if (this.lead.admission === 'demande admission envoyée' || this.lead.admission === 'demande admission acceptée') {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
